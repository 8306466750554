<template>
  <section class="main-section bg-gray60">
    <main-section-header title="담당자 관리" :subtitle="subtitle[currentCompany]" />
    <div class="container">
      <list-utility-menu
        :hasSearchInput="true"
        placeholder="이름, 이메일 아이디로 검색하세요"
        @input="onChangeKeyword"
        @button="onMoveSearchedPage(1)"
        @keyupenter="onMoveSearchedPage(1)"
        :value="keyword"
      >
        <button-basic
          slot="button"
          color="blue"
          size="s"
          :width="113"
          :height="32"
          @click="onMoveUserResister"
          v-if="isSupervisorOrFactory"
        >
          <plus-icon />
          등록
        </button-basic>
      </list-utility-menu>
      <list
        @sort="onClickSort"
        :list="userList"
        :headerList="userHeaderList"
        is-possible-hover-list="isPossibleHoverList"
      >
        <li slot="list-body" v-for="(user, index) in userList" :key="index" @click="onMoveUserDetail(user.id)">
          <span>{{ user.createDT | localDateYYYYMMDD }}</span>
          <span>{{ user.userName }}</span>
          <span>{{ user.loginId }}</span>
          <span>{{ user.role | roleLabel }}</span>
          <span>
            <template v-if="isManufacturerComp">
              {{ user.userEquipmentCnt || '-' }}
            </template>
            <template v-else>
              {{ user.userFactoryEquipmentCnt || '-' }}
            </template>
          </span>
        </li>
        <p slot="no-list-description">{{ noListDescription }}</p>
      </list>
      <pagination
        v-if="totalPageCount > 1"
        :page="page"
        :totalPageCount="totalPageCount"
        :pageSize="pageSize"
        @setPageNum="setPageNum"
      />
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import List from '@/component/list/List';
import MainSectionHeader from '@/component/mainSectionHeader/MainSectionHeader';
import ListHeaderData from './ListHeaderData.json';
import Pagination from '@/component/pagination/Pagination';
import ListUtilityMenu from '@/component/list/ListUtilityMenu.vue';
import PlusIcon from 'vue-material-design-icons/Plus';
import noListDescription from '@/asset/js/noListDescription';

export default {
  name: 'UserList',
  props: [],
  data() {
    return {
      subtitle: {
        M: '슈퍼바이저, 스태프를 등록/수정/삭제할 수 있습니다.',
        F: '매니저, 멤버를 등록/수정/삭제할 수 있습니다.',
      },
      page: 1,
      pageSize: 20,
      keyword: '',
      sortOrder: '',
      totalPageCount: 0,
      userList: null,
      userHeaderList: ListHeaderData,
      isRequestedDataWithKeyword: false,
    };
  },
  computed: {
    ...mapState('users', ['myInfo', 'currentCompany']),
    ...mapGetters('users', ['isSupervisorOrFactory', 'isManufacturerComp']),
    noListDescription() {
      return noListDescription(this.isRequestedDataWithKeyword);
    },
  },
  created() {
    this.getUsers();
  },
  mounted() {},
  watch: {
    $route() {
      this.getUsers();
    },
  },
  methods: {
    ...mapActions('factoryUsers', ['GET_FACTORY_USERS']),
    ...mapActions('manufacturerUsers', ['GET_MANUFACTURER_USERS']),
    setQueryParams() {
      this.keyword = this.$route.query.keyword || '';
      this.sortOrder = this.$route.query.sortOrder || '';
      this.page = this.$route.query.page || 1;
    },
    getUsers() {
      this.setQueryParams();
      this.userList = null;
      this.isRequestedDataWithKeyword = !!this.keyword;
      this.totalPageCount = 0;
      switch (this.currentCompany) {
        case 'M':
          this.getManufacturerUsers();
          break;
        case 'F':
          this.getFactoryUsers();
          break;
      }
    },
    async getFactoryUsers() {
      const factoryUsers = await this.GET_FACTORY_USERS([this.sortOrder, this.page, this.pageSize, this.keyword]);
      this.userList = factoryUsers.result;
      this.totalPageCount = factoryUsers['metaData'].totalPageCount;
    },
    async getManufacturerUsers() {
      const manufacturerUsers = await this.GET_MANUFACTURER_USERS([
        this.sortOrder,
        this.page,
        this.pageSize,
        this.keyword,
      ]);
      this.userList = manufacturerUsers.result;
      this.totalPageCount = manufacturerUsers['metaData'].totalPageCount;
    },
    onMoveUserResister() {
      this.$router.push(`/users/register`).catch((err) => err);
    },
    onMoveUserDetail(userId) {
      this.$router.push(`/users/${userId}`).catch((err) => err);
    },
    onMoveSearchedPage(page) {
      this.$router
        .push({
          query: {
            sortOrder: this.sortOrder || undefined,
            page: page || this.page || undefined,
            keyword: this.keyword || undefined,
          },
        })
        .catch((err) => err);
    },
    setPageNum(page) {
      this.page = page;
      this.onMoveSearchedPage();
    },
    onChangeKeyword(changeKeyword) {
      this.keyword = changeKeyword;
    },
    onClickSort(sort) {
      this.sortOrder = `${this.sortOrder.indexOf('-') === 0 ? '' : '-'}${sort}`;
      this.onMoveSearchedPage();
    },
  },
  components: { MainSectionHeader, List, Pagination, ListUtilityMenu, PlusIcon },
};
</script>

<style scoped lang="scss">
@import 'UserList';
</style>
